
.app-breadCrumb {
	.goBack {
		height: 24px;
		position: absolute;
		right: 5px;
		top: 7px;
		padding: 3px 10px;
		color: #398e8d;
		background-color: #fff;
	}
}
