.app-layout {
  height: 100%;
  background-color: #f0f2f5;
  padding-top: 60px;
  display: flex;
  transition: all 0.6s;
}
.app-layout .app-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: #58a3f7;
  color: #fff;
}
.app-layout .app-leftMenu {
  width: 180px;
  height: 100%;
  background-color: #fff;
  transition: all 0.6s;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.app-layout .app-main {
  flex: 1;
  overflow: hidden;
}
.app-layout .app-breadCrumb {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 40px;
  align-items: center;
  background-color: #fff;
  padding: 0 10px;
  position: relative;
}
.app-layout .app-main-container {
  height: calc(100% - 40px - 20px);
  margin: 10px;
  background-color: #fff;
}
.back-btn {
  height: 38px;
  position: absolute;
  right: 5px;
  top: 62px;
  cursor: pointer;
}
.back-btn img {
  height: 90%;
}
.go-back-btn {
  height: 24px;
  position: absolute;
  right: 10px;
  top: 67px;
  line-height: 0;
  z-index: 1;
}
