.blue-text {
  color: #60adf9;
  cursor: pointer;
}
.btn-blue {
  color: #fff !important;
  background-color: #60adf9 !important;
  border: none;
}
.btn-blue:hover {
  color: #fff !important;
  background-color: rgba(37, 108, 203, 0.9) !important;
}
.btn-blue:focus {
  color: #fff !important;
  background-color: #256CCB !important;
}
.btn-success {
  color: #fff !important;
  background-color: #0eac81 !important;
  border: 1px solid #0eac81 !important;
}
.btn-success:hover {
  color: #fff !important;
  background-color: rgba(14, 172, 129, 0.9) !important;
}
.btn-success.is-disabled {
  background-color: rgba(14, 172, 129, 0.5) !important;
}
.btn-success:focus {
  color: #fff !important;
  background-color: #0eac81 !important;
}
.status-icon {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}
.success-status:before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #0eac81;
  box-shadow: 0px 0px 4px 0px #0eac81;
}
.error-status:before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #ca0a14;
  box-shadow: 0px 0px 4px 0px #ca0a14;
}
.warning-status:before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #e7a23c;
  box-shadow: 0px 0px 4px 0px #e7a23c;
}
.over-status:before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #72AA2C;
  box-shadow: 0px 0px 4px 0px #72AA2C;
}
.table-btn {
  height: 24px !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
}
.table-btn.is-disabled {
  color: #dcdcdc !important;
  border: 1px solid #dcdcdc !important;
  background-color: transparent !important;
}
.filter-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.filter-layout .filter-box {
  border-bottom: 1px solid #DCDCDC;
  position: relative;
}
.filter-layout .filter-box .el-form-item {
  margin-bottom: 10px;
}
.filter-layout .filter-box .control-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  padding: 0 10px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #60ADF9;
  background-color: #fff;
  border: 1px solid #DCDCDC;
  border-top-color: #fff;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  cursor: pointer;
}
.filter-layout .filter-content {
  flex: 1;
}
.primary-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.primary-layout .primary-header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.primary-layout .primary-header .left-tabs {
  overflow: hidden;
}
.primary-layout .primary-header .left-text {
  font-size: 14px;
  color: #202020;
}
.primary-layout .primary-header .left-btn {
  font-size: 14px;
  color: #202020;
}
.primary-layout .primary-header .btnGroups {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.primary-layout .primary-header .el-tabs__nav-scroll {
  background-color: transparent;
}
.primary-layout .primary-header .el-tabs__item {
  margin-right: 0;
}
.primary-layout .primary-header .el-tabs__item.is-active {
  background-color: transparent;
}
.primary-layout .primary-content {
  flex: 1 1 0;
  overflow: hidden;
}
.content-layout {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}
.w-table-layout {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
}
.w-table-layout .w-table-content {
  height: 100%;
}
.w-table-layout .w-table-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding: 5px 0;
  background: #fff;
  text-align: center;
  white-space: nowrap;
}
.w-table-layout .w-table-footer .vxe-pager {
  height: 100%;
}
.w-table-layout .w-table-pager {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
}
.w-table-page {
  padding-bottom: 45px;
}
.btn {
  padding: 9px 50px;
  height: 40px !important;
}
.table-btn {
  height: 24px;
  padding: 5px 10px !important;
  font-size: 12px !important;
}
.table-btn.is-disabled {
  color: #dcdcdc !important;
  border: 1px solid #dcdcdc !important;
  background-color: transparent !important;
}
.vxe-table thead {
  height: 50px !important;
}
.vxe-table tbody td {
  height: 40px !important;
}
.commission-tabs {
  height: 32px !important;
}
.commission-tabs .el-tabs__item {
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #f0f0f0;
  border-radius: 3px 3px 0px 0px;
  color: #666;
  margin-right: 20px;
  padding: 0 10px !important;
}
.commission-tabs .el-tabs__item.is-active {
  color: #fff;
  background-color: #60adf9 !important;
}
.commission-tabs .el-tabs__nav-wrap::after {
  background-color: transparent;
  height: 1px;
  z-index: 999;
}
.commission-tabs .el-tabs__active-bar {
  background-color: transparent;
}
/* 搜索式布局 */
.w-page-main {
  position: relative;
  padding-top: 48px;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.w-page-main .w-page-header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px;
  width: 100%;
  height: 48px;
  line-height: 48px;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
}
.w-page-main .w-page-header .left-tabs {
  margin-right: auto;
}
.w-page-main .w-page-header .left-tabs span {
  font-size: 14px;
  padding: 0 7px;
  cursor: pointer;
  display: inline-block;
}
.w-page-main .w-page-header .left-tabs .is-active {
  color: #1c78c0;
  font-weight: 700;
  height: 38px;
  border-bottom: 2px solid #1c78c0;
}
.w-page-main .w-page-header .left-text {
  margin-right: auto;
}
.w-page-main .w-page-header .left-text span {
  font-size: 14px;
  padding: 0 7px;
  display: inline-block;
}
.w-page-main .w-page-header .btnGroups {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}
.w-page-main .w-page-container {
  height: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}
.tooltipWidth {
  max-width: 600px !important;
  line-height: 30px;
}
.table-content-audit {
  display: inline;
  width: 85%;
}
.custom-descriptions .el-descriptions-item__label {
  width: 200px !important;
  text-align: right !important;
}
.custom-descriptions .el-descriptions-item__content {
  min-width: 232px;
}
.custom-descriptions .el-descriptions-item__label,
.custom-descriptions .el-descriptions-item__content {
  height: 50px !important;
  background-color: #fff !important;
  color: #000 !important;
  font-size: 14px !important;
}
.custom-descriptions .is-bordered .el-descriptions-item__cell {
  border-width: 2px !important;
}
.custom-descriptions .h200 {
  min-height: 200px !important;
  height: 200px !important;
  line-height: normal !important;
  vertical-align: text-top;
}
.custom-descriptions .pd0 {
  padding: 0px !important;
}
.custom-descriptions .text-center {
  text-align: center !important;
}
.custom-descriptions .w250 {
  width: 250px !important;
  min-width: 250px !important;
}
.sp-descriptions .el-descriptions-item__label {
  width: 144px !important;
  min-width: 144px;
  text-align: right !important;
}
.sp-descriptions .text-center {
  text-align: center !important;
}
.sp-descriptions .el-descriptions-item__content {
  width: 188px;
  min-width: 188px;
}
.sp-descriptions .w110 {
  width: 106px !important;
  min-width: 106px !important;
}
.sp-descriptions .w100 {
  width: 100px !important;
  min-width: 100px;
}
.ql-editor img {
  width: 100%;
}
.tag-box .el-scrollbar .el-select-dropdown__wrap .el-select-dropdown__list {
  display: flex !important;
  flex-wrap: wrap;
  padding: 6px;
}
.tag-box .el-scrollbar .el-select-dropdown__wrap .el-select-dropdown__list .el-select-dropdown__item {
  padding: 5px 10px;
  height: 25px;
  line-height: 15px;
  border: 1px solid #c9e4ff;
  color: #409eff;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 3px;
}
.tag-box .el-scrollbar .el-select-dropdown__wrap .el-select-dropdown__list .el-select-dropdown__item.selected {
  border: 1px solid #409eff;
}
.tag-box .el-scrollbar .el-select-dropdown__wrap .el-select-dropdown__list .el-select-dropdown__item.selected::after {
  display: none !important;
}
.projectSurveyTooltip {
  width: 500px !important;
}
.bm-tooltip {
  max-width: 600px !important;
  line-height: 25px !important;
}
.tag-tooltip {
  max-height: 160px;
  overflow-y: auto;
}
.label-tooltip {
  max-width: 600px;
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
}
.opacityHide {
  opacity: 0.6 !important;
}
.vue-treeselect__menu-container {
  font-size: 14px !important;
}
.more-dialog-home .el-dialog__body {
  max-height: 630px !important;
}
.more-dialog-home .el-dialog__footer {
  border-top: 1px solid #eee;
}
.more-dialog-home .el-dialog__footer .el-input-group__append {
  background-color: #60adf9;
  color: #fff;
}
.money-input-inner .el-input__inner {
  line-height: 1px !important;
}
.status-style {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
}
.ToBeOperating {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #E7A23C;
  background: #FDF6EC;
  border: 1px solid #FAECD8;
}
.operating {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #66C239;
  background: #F0F9EB;
  border: 1px solid #E1F3D8;
}
.operated {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #39C098;
  background: #E9F6F2;
  border: 1px solid #BFF5E5;
}
.operated2 {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #0097A7;
  background: #E0F7FA;
  border: 1px solid #B2EBF2;
}
.toProcess {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #419EFF;
  background: #ECF5FF;
  border: 1px solid #D9ECFF;
}
.toRejected {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #F56C6C;
  background: #FEEFF0;
  border: 1px solid #FDE2E2;
}
.toGiveUp {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #909398;
  background: #F4F4F5;
  border: 1px solid #E9E9EB;
}
.progressDialog {
  z-index: 9999999;
}
.progressDialog .progressBox .progressCol {
  height: 32px;
  padding-top: 8px;
}
.progressDialog .progressBox .progressInfo {
  font-size: 12px;
  line-height: 40px;
}
.progressDialog .inputTitle {
  line-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom-collapse {
  border-bottom: none;
}
.custom-collapse .el-collapse-item {
  margin-bottom: 15px;
}
.custom-collapse .el-collapse-item__header {
  padding-left: 15px;
  font-size: 16px;
  background-color: #eff6fe;
}
.custom-collapse .el-collapse-item__wrap {
  border-bottom: none;
}
.custom-collapse .el-collapse-item__content {
  padding: 15px;
}
.custom-dialog-footer {
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.custom-table-height {
  height: 30px !important;
}
.reset-dialog-height .el-dialog__body {
  max-height: 750px !important;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-none {
  border: none !important;
}
.custom-total {
  background-color: #fffff8 !important;
}
.custom-sTotal {
  background-color: #f3fbfe !important;
}
.custom-Total1 {
  background-color: #f3fcfa !important;
}
.custom-input-inner .el-input__inner {
  width: 300px !important;
}
.borderTop {
  border-top: 1px solid #eee;
  margin-top: 10px;
  padding-top: 10px;
}
.custom-active-cell {
  color: #27a9ff !important;
}
