.w-split-layout {
  position: relative;
  width: 100%;
  height: 100%;
}
.w-split-layout .w-split-pane {
  position: absolute;
}
.w-split-layout .w-split-trigger {
  position: absolute;
  z-index: 10;
}
.w-split-layout .w-split-trigger-horizontal {
  width: 100%;
  height: 0;
  left: 0;
}
.w-split-layout .w-split-trigger-vertical {
  width: 0;
  height: 100%;
  top: 0;
}
.w-split-layout .w-trigger-bar-horizontal {
  height: 4px;
  width: 1px;
  background: rgba(23, 35, 61, 0.25);
  float: left;
  margin-left: 3px;
}
.w-split-layout .w-trigger-bar-vertical {
  width: 4px;
  height: 1px;
  background: rgba(23, 35, 61, 0.25);
  float: left;
  margin-top: 3px;
}
.w-split-layout .w-split-horizontal .left-pane,
.w-split-layout .w-split-horizontal .right-pane {
  top: 0;
  bottom: 0;
}
.w-split-layout .w-split-horizontal .left-pane {
  left: 0;
}
.w-split-layout .w-split-horizontal .right-pane {
  right: 0;
  padding-left: 6px;
}
.w-split-layout .w-split-horizontal .w-trigger-vertical {
  width: 6px;
  height: 100%;
  background: #f8f8f9;
  border: 1px solid #dcdee2;
  border-top: none;
  border-bottom: none;
  cursor: col-resize;
}
.w-split-layout .w-split-horizontal .w-vertical-bar {
  position: absolute;
  left: 1px;
  top: 50%;
  overflow: hidden;
  height: 32px;
  transform: translate(0, -50%);
}
.w-split-layout .w-split-vertical .top-pane,
.w-split-layout .w-split-vertical .bottom-pane {
  left: 0;
  right: 0;
}
.w-split-layout .w-split-vertical .top-pane {
  top: 0;
}
.w-split-layout .w-split-vertical .bottom-pane {
  bottom: 0;
}
.w-split-layout .w-split-vertical .w-trigger-horizontal {
  width: 100%;
  height: 6px;
  background: #f8f8f9;
  border: 1px solid #dcdee2;
  border-left: none;
  border-right: none;
  cursor: row-resize;
}
.w-split-layout .w-split-vertical .w-horizontal-bar {
  position: absolute;
  top: 1px;
  left: 50%;
  overflow: hidden;
  width: 32px;
  transform: translate(-50%, 0);
}
