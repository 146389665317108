
@import url('./LayoutIndex.less');

.back-btn {
	height: 38px;
	position: absolute;
	right: 5px;
	top: 62px;
	cursor: pointer;
	img {
		height: 90%;
	}
}

.go-back-btn {
	height: 24px;
	position: absolute;
	right: 10px;
	top: 67px;
	line-height: 0;
	z-index: 1;
	// margin-top: 3px;
}
