.app-leftMenu {
  position: relative;
}
.app-leftMenu .top-btn {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.app-leftMenu .top-btn img {
  height: 40%;
  width: 89%;
}
.app-leftMenu .bottom-menu {
  height: calc(100% - 80px);
  transition: all 0.6s;
}
.app-leftMenu .pd-menu {
  padding: 0;
}
.app-leftMenu .collapse-btn {
  position: absolute;
  right: -9px;
  top: 30%;
  width: 10px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 100px 100px 0px;
  cursor: pointer;
  color: #60adf9;
}
.app-leftMenu .collapse-btn i {
  font-size: 12px;
}
.app-leftMenu .app-elMenu {
  border-right: none;
}
.app-leftMenu .el-menu--collapse {
  width: 60px;
}
.app-leftMenu .el-menu-item,
.app-leftMenu .el-submenu__title {
  height: 38px;
  line-height: 38px;
  margin-bottom: 5px;
  color: #666;
}
.app-leftMenu .el-menu-item.is-active,
.app-leftMenu .el-menu-item:focus {
  background-color: #f5f6fa;
  border-radius: 20px;
  color: #60adf9;
}
.app-leftMenu .el-menu-item.is-active .icon-color,
.app-leftMenu .el-menu-item:focus .icon-color {
  color: #60adf9;
}
.app-leftMenu .el-menu-item:hover {
  background-color: transparent;
  color: #60adf9;
}
.app-leftMenu .el-menu-item:hover .icon-color {
  color: #60adf9;
}
.app-leftMenu .el-submenu .el-submenu__title:hover {
  background-color: transparent;
}
.app-leftMenu .el-submenu .el-menu-item {
  height: 38px;
  line-height: 38px;
  min-width: unset;
  padding: 0 20px 0 40px;
}
.app-leftMenu .el-submenu .el-menu-item.is-active,
.app-leftMenu .el-submenu .el-menu-item:focus {
  background-color: transparent;
}
.collapse-menu .top-btn img {
  height: 40%;
  width: 60%;
}
.collapse-menu .el-menu-item .svg-icon {
  margin-left: 3px;
}
.collapse-menu .el-menu-item.is-active,
.collapse-menu .el-menu-item:focus {
  border-radius: unset;
}
.icon-color {
  color: #7A94B1;
}
