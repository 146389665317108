/* fade 淡入淡出动画 */
.fade-enter,
.fade-leave-to {
    opacity: 0
}

.fade-leave,
.fade-enter-to {
    opacity: 1
}

.fade-enter-active,
.fade-leave-active {
    transition: all .25s
}

/* scale 缩放动画效果 */
.scale-enter,
.scale-leave-to {
    transform: scale(0)
}

.scale-leave,
.scale-enter-to {
    transform: scale(1)
}

.scale-enter-active,
.scale-leave-active {
    transition: all .2s
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
    transition: all .5s;
}

.fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
}

.fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
    transition: all .5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
    opacity: 0;
    transform: translateX(20px);
}

.breadcrumb-move {
    transition: all .5s;
}

.breadcrumb-leave-active {
    position: absolute;
}