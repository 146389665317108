.app-layout {
    @topHeight: 60px;
    @leftWidth: 180px;
    @breadHeight: 40px;

    height: 100%;
    background-color: #f0f2f5;
    padding-top: @topHeight;
    display: flex;
    transition: all 0.6s;

    .app-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: @topHeight;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        background-color: #58a3f7;
        color: #fff;
    }

    .app-leftMenu {
        width: @leftWidth;
        height: 100%;
        background-color: #fff;
        transition: all 0.6s;
        box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
        z-index: 1;
    }

    .app-main {
        flex: 1;
        overflow: hidden;
    }

    .app-breadCrumb {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        height: @breadHeight;
        align-items: center;
        background-color: #fff;
        padding: 0 10px;
        position: relative;
    }

    .app-main-container {
        height: calc(~'100% - @{breadHeight} - 20px');
        margin: 10px;
        background-color: #fff;
    }
}