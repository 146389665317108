.newUpload-layout .el-upload-dragger {
  width: 100%;
  height: 100%;
}
.newUpload-layout .el-upload-dragger .el-icon-upload {
  margin: 6% 0 4%;
}
.newUpload-layout .file-item {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}
.newUpload-layout .file-item .item-left {
  width: 80%;
  padding: 0 5px;
  box-sizing: border-box;
}
.newUpload-layout .file-item .item-right {
  width: 20%;
  text-align: right;
}
.newUpload-layout .file-item .item-right em {
  cursor: pointer;
}
.newUpload-layout .upload-title {
  height: 36px;
  line-height: 36px;
  display: flex;
  font-size: 14px;
  color: #606266;
}
.newUpload-layout .upload-title .label {
  width: 80px;
  text-align: left;
}
.newUpload-layout .upload-title .tips-text {
  font-size: 12px;
}
.newUpload-layout .upload-title .upload-container {
  flex: 1;
}
.newUpload-layout .upload-title .el-upload {
  text-align: left;
}
.newUpload-layout .file-name {
  color: #60adf9;
  text-decoration: underline;
}
.newUpload-layout .picture-demo {
  overflow: hidden;
}
.newUpload-layout .picture-demo .el-upload--picture-card {
  width: 100%;
  height: 100%;
  display: flex;
  line-height: unset;
  flex-direction: column;
  justify-content: center;
  opacity: 1;
  transition: all 1s ease;
}
.newUpload-layout .picture-demo .el-upload-list--picture-card {
  opacity: 0;
  transition: all 1s ease;
}
.newUpload-layout .picture-demo .el-upload-list__item {
  width: 100%;
  height: 100%;
  margin: 0;
}
.newUpload-layout .picture-demo .picture-box {
  width: 100%;
  height: 100%;
}
.newUpload-layout .picture-demo img {
  width: 100%;
  height: 100%;
}
.newUpload-layout .picture-demo .el-icon-upload {
  color: #c0c4cc;
  font-size: 30px;
  line-height: 30px;
  margin: 0;
}
.newUpload-layout .picture-demo .el-upload__text {
  font-size: 12px;
  color: #3f9dff;
}
.newUpload-dsiabled .el-upload-dragger {
  cursor: default;
  background-color: #f5f5f5;
  border-color: transparent;
}
.newUpload-dsiabled .el-upload {
  cursor: not-allowed;
  display: none;
}
.hideUpload .el-upload--picture-card {
  opacity: 0 !important;
}
.hideUpload .el-upload-list--picture-card {
  display: flex;
  opacity: 1 !important;
}
.hideUpload .el-upload {
  display: none;
}
.view-upload-style .el-upload {
  display: none !important;
}
.btn {
  padding: 0 50px !important;
}
