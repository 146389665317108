*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;    // 继承父元素的
}

html {
    height    : 100%;
    box-sizing: border-box;
    position: relative;
}

body,
textarea {
    height: 100%;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    color: #202020;
    position: relative;
}

#app {
    height: 100%;
}

a:focus,
a:active {
    outline: none;
}

a,
a:focus,
a:hover {
    cursor         : pointer;
    color          : inherit;
    text-decoration: none;
}

div:focus {
    outline: none;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.clearfix {
    &:after {
        visibility: hidden;
        display   : block;
        font-size : 0;
        content   : " ";
        clear     : both;
        height    : 0;
    }
}

.hidden {
    display: none;
}

.flex {
    display: flex;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y {
    overflow-y: auto;
}

.text-overflow {
    text-overflow: ellipsis;
    overflow     : hidden;
    white-space  : nowrap;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.w50 {
    width: 50%;
}

.w100 {
    width: 100%;
}

.h100 {
    height    : 100%;
    box-sizing: border-box;
}

ul,
li {
    list-style: none;
}

::-webkit-scrollbar {
    width : 5px;
    height: 7px;
}

::-webkit-scrollbar-track {
    border-radius   : 5px;
    background-color: #eee;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background   : #a5a0a0;
    min-height   : 10px;
}

// padding
.pdl10 {
    padding-left: 10px !important;
}

.pdl30 {
    padding-left: 30px !important;
}

.pdl40 {
    padding-left: 40px !important;
}

.pdr5 {
    padding-right: 5px !important;
}

.pdr10 {
    padding-right: 10px !important;
}

.pdb5 {
    padding-bottom: 5px !important;
}

.pdb10 {
    padding-bottom: 10px !important;
}

.pdt0 {
    padding-top: 0 !important;
}

.pdt5 {
    padding-top: 5px;
}

.pdt10 {
    padding-top: 10px;
}

// margin
.mt5 {
    margin-top: 5px;
}
.mt10 {
    margin-top: 10px;
}
.mt15 {
    margin-top: 15px;
}
.mt20 {
    margin-top: 20px;
}
.mb10 {
    margin-bottom: 10px;
}
.mb20 {
    margin-bottom: 20px;
}
.mb30 {
    margin-bottom: 30px !important;
}

.ml5 {
    margin-left: 5px;
}
.ml10 {
    margin-left: 10px;
}
.ml15 {
    margin-left: 15px;
}
.ml10 {
    margin-left: 10px;
}
.ml20 {
    margin-left: 20px;
}

.mr5 {
    margin-right: 5px;
}
.mr10 {
    margin-right: 10px;
}
.mr20 {
    margin-right: 20px;
}

.tc {
    text-align: center;
    .cell {
        text-align: center;
    }
}

.firstTableSerial {
    .cell {
        padding: 0 5px;
        text-align: center;
    }
}

.tableSerial {
    .cell {
        padding: 0 5px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.is-required-item {
    display: flex;
    align-items: center;

    &:before {
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
    }
}

.position-relative {
    position: relative;
}

// 字体大小
.font-12 {
    font-size: 12px;
}

// 公用接口loading
.interfaceLoading {
    z-index          : 3000;
    position         : fixed;
    top              : 50%;
    left             : 50%;
    width            : 100px;
    height           : 100px;
    background       : rgba(102, 102, 102, 0.7);
    border-radius    : 5px;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform        : translateY(-50%) translateX(-50%);

    .el-loading-mask {
        background-color: transparent;

        .path {
            stroke: white;
        }
    }
}