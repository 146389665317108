.el-dropdown {
  color: unset;
}
.max-tooltip {
  max-width: 500px;
}
.full-tabs {
  height: 100%;
}
.full-tabs .el-tabs__header {
  border-color: #dcdcdc;
  margin-bottom: 10px;
}
.full-tabs .el-tabs__header .el-tabs__item.is-active {
  color: #000;
  background-color: #fff;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
}
.full-tabs .el-tabs__header .el-tabs__item.is-disabled {
  background: linear-gradient(180deg, #FFFFFF 0%, #DBDCDB 100%);
  border-radius: 3px 3px 0px 0px;
  border: 1px solid #DCDCDC;
  color: #999;
}
.full-tabs .el-tabs__content {
  height: calc(100% - 33px - 10px);
}
.full-tabs .el-tab-pane {
  height: 100%;
}
.card-tabs .el-tabs__nav {
  border: none !important;
}
.card-tabs .el-tabs__nav-scroll {
  background-color: #f0f2f5;
}
.card-tabs .el-tabs__item {
  height: 32px;
  line-height: 32px;
  border-radius: 3px 3px 0px 0px;
  margin-right: 8px;
}
.info-card {
  margin-bottom: 10px;
  border: none;
  box-shadow: unset !important;
}
.info-card .el-card__header {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  color: #000;
  background-color: #EFF6FE;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  cursor: pointer;
}
.info-card .el-card__header .card-header-icon {
  font-size: 20px;
  color: #7A94B1;
  margin-right: 10px;
}
.info-card .el-card__body {
  padding: 0;
}
/* form 表单 */
.el-form-item {
  margin-bottom: 15px;
}
.el-form-item .el-select {
  width: 100%;
}
.el-form-item .el-date-editor.el-input,
.el-form-item .el-date-editor.el-input__inner {
  width: 100%;
}
.el-form-item .el-popper[x-placement^=bottom] {
  margin-top: 3px;
}
.el-form-item .el-cascader {
  width: 100%;
}
.el-form-item .el-input-group {
  vertical-align: unset;
}
.el-form-item .el-input-group__append {
  min-width: 68px;
  text-align: center;
}
.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
/********* el-input  *******/
.el-input.is-disabled .el-input__inner {
  color: #666;
}
/********* 弹窗 **********/
.el-dialog__header {
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  border-bottom: 1px solid #ccc;
  font-family: 'Microsoft YaHei';
  background: #60adf9;
}
.el-dialog__header .el-dialog__headerbtn {
  top: 15px;
}
.el-dialog__header .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff !important;
}
.el-dialog__header .el-dialog__title {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
}
.el-dialog__header .el-dialog__close {
  color: #eee;
}
.el-dialog__body {
  padding: 20px;
  max-height: 800px;
  overflow: auto;
  box-sizing: border-box;
}
.el-dialog__footer {
  padding: 10px;
  border-top: 1px solid #eee;
}
.el-dialog__footer .el-button {
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
}
/********* el-tree ************/
.el-tree .el-tree-node__content {
  height: 30px;
  line-height: 30px;
}
.el-tree .custom-tree-node {
  height: 30px;
  line-height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 10px;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #60adf9;
  color: #fff;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content .el-button--text {
  color: #fff;
}
/****** vxe-table *****/
#primaryColor,
.vxe-button.type--button.theme--primary:not(.is--disabled) {
  color: #2671CC !important;
  background-color: #F8FAFD !important;
  border-color: #C3D7F0 !important;
}
#primaryHoverColor,
.vxe-button.type--button.theme--primary:not(.is--disabled):hover {
  color: #fff !important;
  background-color: #5D9DFB !important;
}
#successColor,
.vxe-button.type--button.theme--success:not(.is--disabled) {
  color: #72AA2C !important;
  background-color: #FAFCF8 !important;
  border-color: #D7E6C4 !important;
}
#successHoverColor,
.vxe-button.type--button.theme--success:not(.is--disabled):hover {
  color: #fff !important;
  background-color: #93C359 !important;
}
#dangerColor,
.vxe-button.type--button.theme--danger:not(.is--disabled) {
  color: #CA0A14 !important;
  background-color: #FDF7F8 !important;
  border-color: #F0BCBE !important;
}
#dangerHoverColor,
.vxe-button.type--button.theme--danger:not(.is--disabled):hover {
  color: #fff !important;
  background-color: #F56C6C !important;
}
.vxe-button.type--button.is--disabled {
  color: #999 !important;
  border-color: #DCDCDC !important;
  background-color: #fff !important;
}
.vxe-pager--wrapper {
  text-align: center;
}
.vxe-pager--wrapper .vxe-select > .vxe-input {
  line-height: 100%;
}
.vxe-table--render-default.border--full .vxe-header--column {
  background-color: #F5F6FA;
  color: #666 !important;
}
.vxe-body--column {
  color: #202020;
}
.vxe-table.size--small .vxe-header--column {
  height: 50px !important;
  color: #202020;
}
.vxe-table .vxe-body--row.row--hover,
.vxe-table .vxe-body--row.row--hover.row--stripe {
  background-color: #e5f6ff !important;
}
.vxe-table .vxe-body--row.row--current {
  background-color: #e5f6ff !important;
}
.vxe-table .vxe-body--row.row--current::after {
  content: ' ';
  position: absolute;
  left: 0;
  width: 5px;
  height: 40px;
  background-color: #2671cc;
}
.vxe-table--fixed-wrapper .vxe-body--row.row--current::after {
  content: none;
}
.vxe-header--row {
  background-color: #f0f0f0 !important;
}
.vxe-cell {
  font-size: 14px;
  font-weight: normal !important;
}
.vxe-cell--label {
  font-size: 13px;
  font-weight: normal !important;
}
.vxe-tree-cell {
  font-size: 13px;
  font-weight: normal !important;
}
.vxe-pager--goto-text,
.vxe-pager--classifier-text {
  vertical-align: middle;
}
/****  ql-editor  ***/
div.ql-editor {
  min-height: 200px;
}
/******  el-tooltip ******/
.el-tooltip__popper {
  max-width: 600px;
}
