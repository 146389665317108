.file-name-layout {
  display: inline-block;
  color: #60adf9;
  cursor: pointer;
  margin-left: 5px;
  width: 100%;
}
.file-name-layout i {
  vertical-align: middle;
}
