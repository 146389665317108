.logo-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.logo-container img {
  height: 100%;
  margin-right: 10px;
}
.logo-container span {
  display: inline-block;
}
.leftMar {
  text-align: left;
  margin: 0 0 10px 100px;
}
.leftMar .passwordTip {
  color: #ccc;
  font-size: 12px;
}
.leftMar .passwordTip i {
  font-size: 16px;
}
.edit-password .el-form-item {
  margin-bottom: 20px !important;
}
.btn {
  height: 40px !important;
  padding: 0 50px !important;
}
.btn-blue:focus {
  color: #fff !important;
  background-color: #256ccb !important;
}
.screen-btn {
  display: inline-block;
  margin-right: 15px;
}
