
.app-leftMenu {
    @btnHeight: 80px;
    position: relative;

    .top-btn {
        height: @btnHeight;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        // border-bottom: 1px solid #dcdcdc;

        img {
            height: 40%;
            width: 89%;
        }
    }

    .bottom-menu {
        height: calc(~"100% - @{btnHeight}");
        transition: all 0.6s;
    }

    .pd-menu {
        // padding: 0 10px;
        padding: 0;
    }

    .collapse-btn {
        position: absolute;
        right: -9px;
        top: 30%;
        width: 10px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 0px 100px 100px 0px;
        cursor: pointer;
        color: #60adf9;

        i {
            font-size: 12px;
        }
    }

    // --el-menu 样式
    .app-elMenu {
        border-right: none;
    }

    .el-menu--collapse {
        width: 60px;
    }

    .el-menu-item, .el-submenu__title {
        height: 38px;
        line-height: 38px;
        margin-bottom: 5px;
        color: #666;
    }

    .el-menu-item.is-active,
    .el-menu-item:focus {
        background-color: #f5f6fa;
        border-radius: 20px;
        color: #60adf9;
        .icon-color {
            color: #60adf9;
        }
    }

    .el-menu-item:hover {
        background-color: transparent;
        color: #60adf9;
        .icon-color {
            color: #60adf9;
        }
    }

    .el-submenu {
        .el-submenu__title:hover {
            background-color: transparent;
        }
        .el-menu-item {
            height: 38px;
            line-height: 38px;
            min-width: unset;
            padding: 0 20px 0 40px;

            &.is-active, &:focus {
                background-color: transparent;
            }
        }
    }
}
.collapse-menu {
    .top-btn {
        img {
            height: 40%;
            width: 60%;
        }
    }

    .el-menu-item .svg-icon {
        margin-left: 3px;
    }

    .el-menu-item.is-active, .el-menu-item:focus {
        border-radius: unset;
        
    }
}
.icon-color {
    color: #7A94B1;
}
