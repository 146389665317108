
.newUpload-layout {
	.el-upload-dragger {
		width: 100%;
		height: 100%;

		.el-icon-upload {
			margin: 6% 0 4%;
		}
	}

	.file-item {
		display: flex;
		justify-content: space-between;
		padding: 0 5px;
		.item-left {
			width: 80%;
			padding: 0 5px;
			box-sizing: border-box;
		}
		.item-right {
			width: 20%;
			text-align: right;
			em {
				cursor: pointer;
			}
		}
	}

	.upload-title {
		height: 36px;
		line-height: 36px;
		display: flex;
		font-size: 14px;
		color: #606266;

		.label {
			width: 80px;
			text-align: left;
		}

		.tips-text {
			font-size: 12px;
		}

		.upload-container {
			flex: 1;
		}

		.el-upload {
			text-align: left;
		}
	}

	.file-name {
		color: #60adf9;
		text-decoration: underline;
	}

	.picture-demo {
		overflow: hidden;
		.el-upload--picture-card {
			width: 100%;
			height: 100%;
			display: flex;
			line-height: unset;
			flex-direction: column;
			justify-content: center;
			opacity: 1;
			transition: all 1s ease;
		}

		.el-upload-list--picture-card {
			opacity: 0;
			transition: all 1s ease;
		}
		.el-upload-list__item {
			width: 100%;
			height: 100%;
			margin: 0;
		}
		.picture-box {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
		}

		.el-icon-upload {
			color: #c0c4cc;
			font-size: 30px;
			line-height: 30px;
			margin: 0;
		}

		.el-upload__text {
			font-size: 12px;
			color: #3f9dff;
		}
	}
}

.newUpload-dsiabled {
	.el-upload-dragger {
		cursor: default;
		background-color: #f5f5f5;
		border-color: transparent;
	}

	.el-upload {
		cursor: not-allowed;
		display: none;
	}
}

.hideUpload {
	.el-upload--picture-card {
		opacity: 0 !important;
		// display: none !important;
	}

	.el-upload-list--picture-card {
		display: flex;
		opacity: 1 !important;
	}

	.el-upload {
		display: none;
	}
}
.view-upload-style {
	.el-upload {
		display: none !important;
	}
}
.btn {
	padding: 0 50px !important;
}
